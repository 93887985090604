import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  // Title
  loginTitle: {
    id: 'title.loginTitle',
    defaultMessage: 'Connexion',
  },
  logoutTitle: {
    id: 'title.logoutTitle',
    defaultMessage: 'Déconnexion',
  },
  createUserTitle: {
    id: 'title.createUserTitle',
    defaultMessage: 'Créer un utilisateur',
  },
  updateUserTitle: {
    id: 'title.updateUserTitle',
    defaultMessage: 'Modifier un utilisateur',
  },
  errorTitle: {
    id: 'title.errorTitle',
    defaultMessage: 'Erreur',
  },

  // Form
  passwordLabel: {
    id: 'form.passwordLabel',
    defaultMessage: 'Mot de passe',
  },
  loginLabel: {
    id: 'form.loginLabel',
    defaultMessage: 'Identifiant ou Email',
  },
  emailLabel: {
    id: 'form.emailLabel',
    defaultMessage: 'Email',
  },
  expiryDateLabel: {
    id: 'form.expiryDateLabel',
    defaultMessage: 'Date d’expiration du compte',
  },
  expiryLabel: {
    id: 'form.expiryLabel',
    defaultMessage: 'Expiration',
  },
  passwordConfirmationLabel: {
    id: 'form.passwordConfirmationLabel',
    defaultMessage: 'Confirmation mot de passe',
  },
  usernameLabel: {
    id: 'form.usernameLabel',
    defaultMessage: 'Identifiant',
  },
  lastNameLabel: {
    id: 'form.lastNameLabel',
    defaultMessage: 'Nom',
  },
  firstNameLabel: {
    id: 'form.firstNameLabel',
    defaultMessage: 'Prénom',
  },
  confirmDeleteLabel: {
    id: 'form.confirmDeleteLabel',
    defaultMessage: 'Oui je suis sûr',
  },
  accessLabel: {
    id: 'form.accessLabel',
    defaultMessage: 'Accès',
  },
  deleteUser: {
    id: 'form.deleteUser',
    defaultMessage: 'Êtes-vous sûr de vouloir supprimer l’utilisateur',
  },
  deleteOf: {
    id: 'form.deleteOf',
    defaultMessage: 'Suppression de',
  },

  // Button
  loginBtn: {
    id: 'btn.loginBtn',
    defaultMessage: 'Se connecter',
  },
  valideBtn: {
    id: 'btn.valideBtn',
    defaultMessage: 'Valider',
  },
  cancelBtn: {
    id: 'btn.cancelBtn',
    defaultMessage: 'Annuler',
  },
  deleteBtn: {
    id: 'btn.deleteBtn',
    defaultMessage: 'Supprimer',
  },
  reload: {
    id: 'btn.reload',
    defaultMessage: 'Rafraichir',
  },

  // Error
  defaultError: {
    id: 'error.defaultError',
    defaultMessage: "Une erreur s'est produite",
  },
  BadCredentialsError: {
    id: 'error.BadCredentialsError',
    defaultMessage: 'Votre identifiant ou votre mot de passe est incorrect',
  },
});
