import React, { Suspense, lazy, memo } from 'react';
import CustomNavbarFallback from './CustomNavbar/CustomNavbarFallback';
const CustomNavbar = lazy(() => import('./CustomNavbar/CustomNavbar'));

const Layout = ({ children }) => (
  <>
    <Suspense fallback={<CustomNavbarFallback />}>
      <CustomNavbar />
    </Suspense>
    <main className="container">
      {children}
    </main>
  </>
);

export default memo(Layout);
