export const ACTIONS = {
  LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',

  LOGOUT: 'AUTH_USERS_LOGOUT',

  CHECKTOKEN_REQUEST: 'AUTH_CHECKTOKEN_REQUEST',
  CHECKTOKEN_SUCCESS: 'AUTH_CHECKTOKEN_SUCCESS',
  CHECKTOKEN_FAILURE: 'AUTH_CHECKTOKEN_FAILURE',

  RESET_ERROR: 'AUTH_RESET_ERROR',
};

export default function AuthReducer(state, action) {
  switch (action.type) {
    case ACTIONS.RESET_ERROR:
      return {
        ...state,
        status: action.type,
        error: null,
      };

    case ACTIONS.LOGIN_REQUEST:
      return {
        ...state,
        status: action.type,
        isLoading: true,
      };

    case ACTIONS.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLogged: true,
        isLoading: false,
        status: action.type,
      };

    case ACTIONS.LOGIN_FAILURE:
      return {
        ...state,
        isLogged: false,
        isLoading: false,
        status: action.type,
        error: action.payload,
      };

    case ACTIONS.LOGOUT:
      return {
        ...state,
        user: null,
        isLogged: false,
        isLoading: false,
        status: action.type,
        error: null,
      };

    case ACTIONS.CHECKTOKEN_REQUEST:
      return {
        ...state,
        status: action.type,
      };

    case ACTIONS.CHECKTOKEN_SUCCESS:
      return {
        ...state,
        status: action.type,
        user: { ...state.user, token: action.payload },
      };

    case ACTIONS.CHECKTOKEN_FAILURE:
      return {
        ...state,
        user: null,
        isLogged: false,
        isLoading: false,
        status: action.type,
        error: action.payload,
      };

    default:
      throw new Error();
  }
}
