import React, { Suspense, lazy } from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import { ROUTES } from './constants/constants';
import PrivateRoute from './tools/PrivateRoute';
import AutoRedirect from './tools/AutoRedirect';
import Layout from './components/Layout';

const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const NotFoundPage = lazy(() => import('./pages/404'));

const App = () => (
  <HashRouter>
    <Layout>
      <Switch>
        <AutoRedirect
          exact
          path={[ROUTES.ROOT, ROUTES.LOGIN]}
          redirectTo={ROUTES.HOME}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Login />
          </Suspense>
        </AutoRedirect>

        <PrivateRoute exact path={ROUTES.HOME}>
          <Suspense fallback={<div>Loading...</div>}>
            <Home />
          </Suspense>
        </PrivateRoute>

        <Route path={ROUTES.NOT_FOUND}>
          <Suspense fallback={<div>Loading...</div>}>
            <NotFoundPage />
          </Suspense>
        </Route>
      </Switch>
    </Layout>
  </HashRouter>
);

export default App;
