import React, { memo } from 'react';

const CustomNavbarFallback = props => (
  <div
    style={{
      position: 'relative',
      height: '51px',
      padding: '.5rem 1rem',
      boxShadow:
        '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    }}
  />
);

export default memo(CustomNavbarFallback);
