import { getClientEnvironment } from "../tools/helpers";

export const ROUTES = {
  HOME: '/home',
  LOGIN: '/login',
  NOT_FOUND: '*',
  ROOT: '/',
};

export const APP_NAME = 'User Manager';
export const APP_INFO = {
  client: 'clients/user_manager',
  environment: `environments/${getClientEnvironment()}`,
};

export const API = {
  AUTH_ENDPOINT: process.env.REACT_APP_API_AUTH,
  SECURITY_LOGIN: 'security/login',
  USERS: 'users',
  BASE_ACCESSES: 'base_accesses',
  TOKEN_DURATION: 300000,
};

export const LOCAL_STORAGE = {
  USER_KEY: 'user',
};
