import React, { useContext, memo } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

/**
 * Redirect user if !isLogged
 */
const AutoRedirect = ({ redirectTo, children, ...rest }) => {
  const {
    authState: { isLogged },
  } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isLogged ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectTo,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default memo(AutoRedirect);
