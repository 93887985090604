import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { IntlProvider } from 'react-intl';
import translations from './i18n/locales';
import AuthContextProvider from './contexts/AuthContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

const usersLocale = navigator.language;
const translationsForUsersLocale = translations[usersLocale];

ReactDOM.render(
  <IntlProvider locale={usersLocale} messages={translationsForUsersLocale}>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </IntlProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
