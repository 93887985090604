import React, { createContext, useReducer, memo, useEffect } from 'react';
import jws from 'jsonwebtoken';
import dayjs from 'dayjs';
import AuthReducer, { ACTIONS } from '../reducers/AuthReducer';
import {
  loginAPI,
  setUserLocalStorage,
  getUserLocalStorage,
  updateUserTokenLocalStorage,
  checkTokenAPI,
  removeUserLocalStorage,
} from '../tools/user';
import { API } from '../constants/constants';

export const AuthContext = createContext();
const initialState = {
  user: null,
  isLogged: false,
  isLoading: false,
  status: null,
  error: null,
};

const AuthContextProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(AuthReducer, initialState);

  useEffect(() => {
    // Start interval check token routine when isLogged = true (user logged)
    if (authState && authState.isLogged) {
      const interval = setInterval(() => checkCurrentToken(), 120000);

      return () => clearInterval(interval);
    }
  }, [authState.isLogged]);

  function checkCurrentToken() {
    if (!authState && !authState.user && !authState.user.token) {
      return;
    }

    const user = getUserLocalStorage();
    const token = user.token;
    const tokenDecoded = jws.decode(token);

    if (!tokenDecoded) {
      return;
    }

    const currentTime = dayjs();
    const tokenExp = dayjs.unix(tokenDecoded.exp);

    // remainingTime is the time (ms) before token expire
    const remainingTime = tokenExp.diff(currentTime);
    // console.debug('token remainingTime min', remainingTime / 1000 / 60);

    if (remainingTime <= 0) {
      // remainingTime <= 0 --- token perimed
      logout()
    }

    if (remainingTime > 0 && remainingTime < API.TOKEN_DURATION) {
      // remainingTime > 0 && remainingTime < ${API.TOKEN_DURATION} --- token need to refresh
      refreshUserToken(token);
    }

    if (remainingTime > API.TOKEN_DURATION) {
      //  remainingTime > ${API.TOKEN_DURATION} --- token valid
    }
  }

  async function refreshUserToken(token) {
    try {
      dispatch({ type: ACTIONS.CHECKTOKEN_REQUEST });
      const { newToken } = await checkTokenAPI(token);

      if (newToken) {
        updateUserTokenLocalStorage(newToken);
        dispatch({ type: ACTIONS.CHECKTOKEN_SUCCESS, payload: newToken });
      }
    } catch (error) {
      dispatch({ type: ACTIONS.CHECKTOKEN_FAILURE, payload: error });
    }
  }

  async function setLogin(credentials) {
    dispatch({ type: ACTIONS.LOGIN_REQUEST });

    try {
      const user = await loginAPI(credentials);

      setUserLocalStorage(user);
      dispatch({ type: ACTIONS.LOGIN_SUCCESS, payload: user });
    } catch (error) {
      dispatch({ type: ACTIONS.LOGIN_FAILURE, payload: error });
    }
  }

  function logout() {
    removeUserLocalStorage();
    dispatch({ type: ACTIONS.LOGOUT });
  }

  function resetAuthError() {
    dispatch({ type: ACTIONS.RESET_ERROR });
  }

  return (
    <AuthContext.Provider value={{ authState, setLogin, logout, resetAuthError }}>
      {children}
    </AuthContext.Provider>
  );
};

export default memo(AuthContextProvider);
