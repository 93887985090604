import Axios from 'axios';
import jws from 'jsonwebtoken';
import { API, LOCAL_STORAGE, APP_INFO } from '../constants/constants';
import { formatApiErrors, handleRequestError } from './api_tool';

// ----------------------------------------------- API
export const loginAPI = (credentials) =>
  new Promise(async (resolve, reject) => {
    const credentialsToSend = {
      ...credentials,
      client: APP_INFO.client,
      environment: APP_INFO.environment,
    };

    // Run API request
    const [response, responseError] = await handleRequestError(
      Axios.post(`${API.AUTH_ENDPOINT}/${API.SECURITY_LOGIN}`, credentialsToSend)
    );

    if (response) {
      const {
        data: { token, user },
      } = response;

      // Decode token data
      const { roles, uuid } = jws.decode(token);

      // Format response object
      const responseToResolve = {
        ...user,
        roles,
        uuid,
        token,
      };

      resolve(responseToResolve);
    } else if (responseError) {
      reject(formatApiErrors(responseError));
    }
  });

export const checkTokenAPI = (token) =>
  new Promise(async (resolve, reject) => {
    // Run API request
    const [response, responseError] = await handleRequestError(
      Axios.get(`${API.AUTH_ENDPOINT}/security/refreshJWTToken`, {
        headers: apiRequestHeader(token),
      })
    );

    if (response) {
      // Format response object
      const responseToResolve = { newToken: response.data.token };

      resolve(responseToResolve);
    } else if (responseError) {
      reject(formatApiErrors(responseError));
    }
  });

// return authorization header with jwt token
export function apiRequestHeader(token) {
  if (!token) {
    return null;
  }

  const header = {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
    Authorization: `Bearer ${token}`,
  };

  return header;
}

// ----------------------------------------------- LocalStorage
/**
 * Return value of localStorage at USER_KEY
 * @return {null | object} null | object
 */
export const getUserLocalStorage = () =>
  JSON.parse(localStorage.getItem(LOCAL_STORAGE.USER_KEY));

/**
 * Set user object in localStorage at USER_KEY
 * @param {object} user
 */
export const setUserLocalStorage = (user) => {
  if (!user) {
    throw new Error(`setUserLocalStorage(user) --- !user --> ${user}`);
  }

  localStorage.setItem(LOCAL_STORAGE.USER_KEY, JSON.stringify(user));
};

/**
 * Update user.token in localStorage at USER_KEY
 * @param {string} newToken
 */
export const updateUserTokenLocalStorage = (newToken) => {
  const oldUserLocalStorage = { ...getUserLocalStorage() };

  if (!newToken && !oldUserLocalStorage) {
    throw new Error(
      `updateUserTokenLocalStorage(newToken) --- !newToken --> ${newToken}`
    );
  }

  const updatedLocalStorage = { ...oldUserLocalStorage, token: newToken };

  removeUserLocalStorage();
  setUserLocalStorage(updatedLocalStorage);
};

/**
 * Remove value of localStorage at USER_KEY
 */
export const removeUserLocalStorage = () =>
  localStorage.removeItem(LOCAL_STORAGE.USER_KEY);
