import dayjs from "dayjs";

// Check if accessToFind object exist in accesses state
export const accessAlreadyExist = (accessToFind, accesses) =>
  accesses.find((access) => access?.baseAccess === accessToFind?.baseAccess);

// Return accesses state array without accessToRemove object
export const accessesUpdated = (accessToRemove, accesses) =>
  accesses.filter(
    (access) => access?.baseAccess !== accessToRemove?.baseAccess
  );

export const formatDate = (date) => date ? dayjs(date).format('YYYY-MM-DD') : null
// Return app environment worded to auth API
export function getClientEnvironment() {
  if (process.env.REACT_APP_ENV === "stage") {
    return 'prod';
  }
  
  return 'prod';
}

// ----------------------
// - IRI TOOLS
// ----------------------
// Return user object field
export const getUsername = (userObj) => userObj?.username;
export const getFirstName = (userObj) => userObj?.firstName;
export const getLastName = (userObj) => userObj?.lastName;
export const getEmail = (userObj) => userObj?.email;
export const getExpiresAt = (userObj) => userObj?.expiresAt;
export const getAccesses = (userObj) => userObj?.accesses;
export const getUUID = (userObj) => userObj?.uuid;
