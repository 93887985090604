import { messages } from '../constants/messages';

export const handleRequestError = (promise) =>
  promise
    .then((data) => [data, null])
    .catch((error) => Promise.resolve([null, error.response]));

export function renderErrorMessage(error, formatMessage) {
  if (!error || !error.status) {
    // return default error
    return formatMessage(messages.defaultError);
  }

  try {
    return formatMessage(messages[`${error?.type}`]);
  } catch (err) {
    return error?.description
      ? error?.description
      : formatMessage(messages.defaultError);
  }
}

const createErrorObj = (error) => {
  try {
    return {
      status: error?.status,
      type: error?.data['@type'],
      title: error?.data['hydra:title'],
      description: error?.data['hydra:description'],
    };
  } catch (error) {
    // return true to show default error
    return true;
  }
};

// Function if you want override a specific status code
export function formatApiErrors(responseError) {
  if (!responseError) {
    return null;
  }

  if (responseError.status) {
    switch (responseError.status) {
      // case 400:
      // case 401:
      // case 403:
      // case 404:
      // case 405:
      // case 409:
      // case 500:
      // case 503:
      default:
        return createErrorObj(responseError);
    }
  }
}
